// Small mostly vanilla JS (some jQuery) library to init Google Maps

const GoogleMap = ( function() {

	function GoogleMap( element, markers ) {
		this.element = element;
		this.markers = markers;

		this.zoom = 16;

		this.options = {
			zoom  		  : this.zoom,
			center		  : new google.maps.LatLng(0, 0),
			mapTypeId	  : google.maps.MapTypeId.ROADMAP,
			scrollwheel   : false,
			mapId         : '485f32c2ce889a6d'
		};

		init.call( this );
	}

	GoogleMap.prototype.add_marker = function( lat, lng ) {
		const latlng = new google.maps.LatLng( lat, lng );
		const marker = new google.maps.Marker( {
			position	: latlng,
			map			  : this.map,
			icon      : ''
		} );

		return marker;
	};

	GoogleMap.prototype.add_info_window = function( marker, html ) {
		const info_window = new google.maps.InfoWindow( {
			content: html
		} );

		google.maps.event.addListener( marker, 'click', function() {
			info_window.open( this.map, marker );
		} );

		return info_window;
	};

	GoogleMap.prototype.center = function() {
		const bounds = get_bounds.call( this );

		if( 1 === this.map.markers.length ) {
	    this.map.setCenter( bounds.getCenter() );
	    this.map.setZoom( this.zoom );
		} else {
			this.map.fitBounds( bounds );
		}
	};

	function get_bounds() {
		const bounds = new google.maps.LatLngBounds();

		for (let i = 0, len = this.map.markers.length; i < len; i++) {
			const marker = this.map.markers[i];
			const latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
			
			bounds.extend( latlng );
		}

		// extend bounds to allow for 250 pixels of label width
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        var offset = 0.004;

        var new_ne = new google.maps.LatLng(ne.lat() + offset, ne.lng() - offset);
        var new_sw = new google.maps.LatLng(sw.lat() - offset, sw.lng() + offset);
        
        bounds.extend(new_ne);
        bounds.extend(new_sw);

		return bounds;
	}

	GoogleMap.prototype.set_zoom = function( zoom ) {
		this.map.setZoom( zoom );
	};

	GoogleMap.prototype.zoom_in = function() {
		this.map.setZoom( this.map.getZoom() + 1 );
	};

	GoogleMap.prototype.zoom_out = function() {
		this.map.setZoom( this.map.getZoom() - 1 );
	};

	function init() {
		this.map = new google.maps.Map( this.element, this.options );
		this.map.markers = [];

		for (let i = 0, len = this.markers.length; i < len; i++) {
			const _marker = this.markers[i];
			const marker = this.add_marker( _marker.lat, _marker.lng );

			this.map.markers.push( marker );

			if ( _marker.hasOwnProperty( 'html' ) && '' !== _marker.html ) {
				this.add_info_window( marker, _marker.html );
			}
		}

		this.center();

		this.element.classList.add('map-initialised');
		trigger_custom_event( this.element, 'map-ready' );

		/**
		 * Recenter the map each time the window is resized.
		 * Center will keep focus on the current position 
		 * of the map *not* the marker that was added.
		 * @see http://stackoverflow.com/questions/8792676/center-google-maps-v3-on-browser-resize-responsive
		 */
		const _ = this;
		google.maps.event.addDomListener(window, 'resize', function() {
			const center = _.map.getCenter();
		  google.maps.event.trigger( _.map, "resize" );
		  _.map.setCenter( center );
		} );
	}

	/**
	 * Trigger a custom event on an element.
	 * @param  {Node}   element    A DOM Node to trigger the event on.
	 * @param  {String} event_name A unique identifier for the event.
	 */
	function trigger_custom_event( element, event_name ) {
		const event = document.createEvent('Event');
		event.initEvent( event_name, true, true );
		element.dispatchEvent( event );
	}

	return GoogleMap;
} )();

function create_map( element ) {
	let markers = Array.prototype.slice.call( element.querySelectorAll('.js-google-map__marker') );

	markers = markers.map( (marker) => {
		return {
			lat: marker.getAttribute('data-lat'),
			lng: marker.getAttribute('data-lng'),
			html: marker.innerHTML
		};
	} );

	jQuery(element).on( 'map-ready', () => {
		jQuery(element).siblings('.js-google-map-preloader').fadeOut( 250 );
	} );

	const map = new GoogleMap( element, markers );
}

// // Load visible maps on page load
const $maps = jQuery('.js-google-map');
$maps.each( (index, map) => {
    const $map = jQuery(map);

    if ( $map.is(':visible') ) {
        create_map( $map.get(0) );
    }
} );
